import styled from 'styled-components'
import { STYLES } from '../../styles/constants'
import linkedInIcon from '../images/linkedin-icon.svg'

export const AccountContainer = styled.div`
  text-align: center;
  padding: 24px 16px;
  min-height: calc(100vh - ${STYLES.HEIGHT_HEADER_MB} - ${STYLES.HEIGHT_FOOTER});

  h1 {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 24px;
    color: #FFFFFF;
    line-height: 40px;
  }

  img {
    width: 48px;
    margin: 0 auto 24px auto;
  }

  p {
    font-size: 16px;
    color: rgba(255,255,255,0.70);
    max-width: 535px;
    margin: 8px auto 0 auto;
    line-height: 28px;
  }

  button.btn-linkedin {
    appearance: none;
    background: #0077B5;
    border: none;
    border-radius: 0;
    color: #FFFFFF;
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_SEMIBOLD};
    text-transform: uppercase;
    padding: 8px 24px 8px 48px;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 24px;
    margin-top: 24px;
    cursor: pointer;
    position: relative;

    :before {
      content: '';
      display: block;
      position: absolute;
      top: 8px;
      left: 12px;
      background-image: url(${linkedInIcon});
      background-size: 100% 100%;
      width: 24px;
      height: 24px;
    }

    :disabled {
      cursor: default;
      pointer-events: none;
      opacity: .6;
    }
  }

  button {
    margin: 24px auto 0 auto;
  }

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    min-height: calc(100vh - ${STYLES.HEIGHT_HEADER_DT} - ${STYLES.HEIGHT_FOOTER});
    padding-top: 72px;

    h1 {
      margin-bottom: 8px;
    }
  }
`;