import React from 'react'
import Helmet from 'react-helmet'
const LinkedinSDK = (typeof window !== `undefined`) ? require('react-linkedin-sdk').default : ''
import accountIcon from './images/account-icon.svg'
import bookmarksIcon from './images/bookmarks-icon.svg'
import { AccountContainer } from './components/AccountContainer'
import { Wrapper } from '../components/Wrapper'



class Login extends React.Component {  
  render() {
    const page = this.props.page ? this.props.page : 'Login';

    return (
      <Wrapper>
        <Helmet title={`${page} | P+N Showcase`}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <AccountContainer>
          <img src={this.props.page === 'Bookmarks' ? bookmarksIcon : accountIcon} alt="" />
          <h1 className="section-headline">{this.props.page === 'Bookmarks' ? 'Bookmarks' : 'Log In'}</h1>
          {this.props.page === 'Bookmarks' &&
            <p>To {this.props.pendingBookmark ? 'add this bookmark' : 'start bookmarking'}, you must first connect with LinkedIn.</p>
          }
          { (typeof window !== `undefined`) &&
            <LinkedinSDK
              clientId="86udp7b6wn97hd"
              callBack={this.props.linkedInCallback}
              fields=":(id,first-name,last-name)"
              loginButtonText={'Connect with Linkedin'}
              logoutButtonText={'Disconnect Linkedin'}
              buttonType={'button'}
              className="btn-linkedin"
              getOAuthToken
              />
          }
        </AccountContainer>
      </Wrapper>
    )
  }
}

export default Login