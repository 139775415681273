import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import ReactGA from 'react-ga'
import Moment from 'react-moment'
import Container from '../components/Container'
import UpdateTile from '../components/UpdateTile'
import { TileGrid } from '../components/TileGrid'
import { Wrapper } from '../components/Wrapper'
import { UpdateHeader } from './update.js'
import styled from 'styled-components'
import { STYLES } from '../styles/constants'
import { CategoryFilter, removeDuplicates } from '../user/bookmarks'

const IndexHeader = styled.div`
  margin-top: 48px;
  text-align: center;
  padding: 0 ${STYLES.PADDING_MOBILE};

  .year {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_SEMIBOLD};
    font-size: 12px;
    color: ${STYLES.COLOR_PN_YELLOW_DARK};
    letter-spacing: 0.5px;
    line-height: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  h1 {
    max-width: 640px;
    margin: 0 auto 16px auto;
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 24px;
    color: #FFFFFF;
    line-height: 36px;
    margin-bottom: 48px;
  }

  p {
    max-width: 640px;
    margin: 0 auto 16px auto;
    font-family: "Noto Sans";
    font-size: 16px;
    color: #FFFFFF;
    line-height: 28px;
  }

  .arrow {
    margin-bottom: 48px;

    svg {
      width: 16px;
      height: 17px;
    }
  }

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    margin-top: 72px;

    h1 {
      margin-bottom: 72px;
    }

    .arrow {
      margin-bottom: 72px;
    }
  }
`;

class CollectionTemplate extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      updates: get(this, 'props.data.contentfulClientCollection').updates,
      filterDropdown: 'closed',
      filter: {}
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  goBack = () => {
    ReactGA.event({
      category: 'Update',
      action: 'Click back arrow',
      label: 'from: ' + this.props.pageContext.slug
    })
    window.history.back()
  }

  getTagsFromUpdates = () => {
    let tags = [];
    this.state.updates.forEach(update => {
      update.tags.forEach(tag => tags.push(tag))
    })

    return removeDuplicates(tags, 'id')
  }

  filter = (tag) => {
    this.setState({ filter: tag })
    this.toggleFilterDropdown()
    ReactGA.event({
      category: 'Filter',
      action: 'Filter updates',
      label: tag.name
    })
  }

  clearFilter = () => {
    this.setState({ filter: {} })
    this.closeFilterDropdown()
    ReactGA.event({
      category: 'Filter',
      action: 'Filter updates',
      label: 'All'
    })
  }

  hasActiveFilter = () => {
    return this.state.filter.hasOwnProperty('id')
  }

  toggleFilterDropdown = () => {
    const newState = this.state.filterDropdown === 'closed' ? 'open' : 'closed'
    this.setState({ filterDropdown: newState })

    if (this.state.filterDropdown === 'open') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }

  closeFilterDropdown = () => {
    this.setState({ filterDropdown: 'closed' })
  }

  handleClick = (e) => {
    if ((this.dropdownRef && this.dropdownRef.contains(e.target)) ||
      (this.toggleRef && this.toggleRef.contains(e.target))) {
      return
    }

    this.closeFilterDropdown()
  }

  getFilteredUpdates = () => {
    if (!this.state.filter.hasOwnProperty('id')) return this.state.updates

    return this.state.updates.filter(update => {
      let match = false
      update.tags.forEach(tag => {
        if (tag.id === this.state.filter.id) match = true
      })
      return match
    })
  }

  render() {
    const collection = get(this, 'props.data.contentfulClientCollection')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const backArrowSVG =
      `<defs>
        <polygon id="shape-a" points="16 7 3.83 7 9.42 1.41 8 0 0 8 8 16 9.41 14.59 3.83 9 16 9"/>
      </defs>
      <use fill="#FFF" xlink:href="#shape-a"/>`

    return (
      <Container>
        <Helmet title={`${collection.title} | ${siteTitle}`}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Wrapper containerPadding>
          <IndexHeader>
			      <div className="year"><Moment format="MMMM YYYY">{collection.date}</Moment></div>
            <h1>{collection.description.description}</h1>
          </IndexHeader>
          <TileGrid>
            {this.getFilteredUpdates().map(update => (
              <UpdateTile update={update} key={update.slug} />
            ))}
          </TileGrid>
        </Wrapper>
      </Container>
    )
  }
}

export default CollectionTemplate

export const pageQuery = graphql`
  query CollectionBySlug($slug: String!) {
    #SITE METADATA
    site {
      siteMetadata {
        title
      }
    }

    contentfulClientCollection(slug: { eq: $slug }) {
      id
      title
      date
      description {
        description
      }
      updates {
        title
        slug
        tags {
          name
          id
        }
        thumbnail {
          sizes(maxWidth: 704, maxHeight: 704) {
            ...GatsbyContentfulSizes
          }
        }
      }
    }
  }
`
