import { localStorageString, initializeUser } from '../../services/db'

export const isBrowser = () => typeof window !== 'undefined'

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('linkedInUser')
    ? JSON.parse(window.localStorage.getItem('linkedInUser'))
    : {}

export const setUser = user => {
  window.localStorage.setItem('linkedInUser', JSON.stringify(user))
  initializeUser(user)
}

export const handleLogin = (user, callback) => {
  if (isLoggedIn()) {
    console.log('logged in')
    callback(getUser())
  } 
  else {
    console.log('logging in...')
    console.log('logged in!')
    setUser(user)
    if (isBrowser()) {
      setTimeout(() => {
        callback(user)
      }, 500)
    }
  }
}

export const isLoggedIn = () => {
  if (!isBrowser()) return false
  const linkedInUser = getUser()
  const user = JSON.parse(window.localStorage.getItem(localStorageString))
  const loggedIn = linkedInUser && user && linkedInUser.hasOwnProperty('id') && user.hasOwnProperty('key')
  console.log('isLoggedIn check')
  console.log(loggedIn)
  return loggedIn
}

export const logout = callback => {
  window.localStorage.removeItem('linkedInUser')
  window.localStorage.removeItem(localStorageString)
  document.getElementsByClassName('btn-linkedin')[0].disabled = true;
  console.log('logging out')
  if (window.hasOwnProperty('IN') && window.IN.hasOwnProperty('User')) {
    let fallback = setTimeout(networkFallback, 10000)
    if (window.IN.User.isAuthorized()) {
      window.IN.User.logout(callback, '')
      clearTimeout(fallback)
    }
  }
  else {
    callback()
  }
}

const networkFallback = () => {
  if (window.confirm("Looks like something went wrong. Do you want to refresh?")) {
    document.location.reload()
  }
}