import React from 'react'
import Helmet from 'react-helmet'
import ReactGA from 'react-ga'
import { handleLogin, isLoggedIn } from './services/auth'
import Login from './login'
import { navigate } from 'gatsby'
import { getBookmarks, addBookmark } from '../services/db'
import UpdateTile from '../components/UpdateTile'
import { TileGrid } from '../components/TileGrid'
import styled from 'styled-components'
import { STYLES } from '../styles/constants'
import { Wrapper } from '../components/Wrapper'
import { AccountContainer } from './components/AccountContainer'
import bookmarksIcon from './images/bookmarks-icon.svg'
import dropdownIcon from '../images/dropdown-icon.svg'
import { Button, buttonThemes } from '../components/Button'

const responseLinkedin = response => {
  ReactGA.event({
    category: 'Account',
    action: 'Login',
    label: 'Login from bookmarks page'
  })
  handleLogin(response, user => navigate(`/user/bookmarks`))
}

const BookmarksContainer = styled.div`
  min-height: calc(100vh - ${STYLES.HEIGHT_HEADER_MB} - ${STYLES.HEIGHT_FOOTER});

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    min-height: calc(100vh - ${STYLES.HEIGHT_HEADER_DT} - ${STYLES.HEIGHT_FOOTER});
  }
`;

const BookmarksHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  padding: 0 16px;
  
  h1 {
    font-family: "Montserrat";
    font-weight: ${STYLES.FW_BOLD};
    font-size: 16px;
    color: #FFFFFF;
    line-height: 24px;
  }

  @media (min-width: ${STYLES.BP_MEDIUM_MIN}) {
    margin-top: 62px;
    margin-bottom: 19px;
    
    h1 {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

export const CategoryFilter = styled.div`
  font-size: 14px;
  color: #FFFFFF;
  text-align: left;
  line-height: 20px;
  position: relative;
  width: 162px;

  .filter-toggle {
    position: relative;
    z-index: 2;
    width: 100%;
    padding: 12px;
    cursor: pointer;
    white-space: nowrap;

    :after {
      content: '';
      background-image: url(${dropdownIcon});
      width: 24px;
      height: 24px;
      background-size: 100% 100%;
      position: absolute;
      top: 10px;
      right: 12px;
      transition: transform .3s;
    }

    &.closed {
      padding-right: 48px;
      text-align: right;
    }

    &.open {
      text-align: left;
      :after {
        transform: rotate(180deg);
      }
    }
  }

  .filter-dropdown {
    background: #333333;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    padding: 54px 0 9px 0;
    width: 100%;

    &.closed {
      opacity: 0;
      pointer-events: none;
    }

    &.open {
      opacity: 1;
      transition: opacity .3s;
    }

    :before {
      content: '';
      position: absolute;
      width: 100%;
      display: block;
      height: 1px;
      left: 0;
      top: 44px;
      background-color: rgba(34,31,31,.5);
    }
  }

  .filter-item {
    padding: 3px 12px;
    white-space: nowrap;
    margin-bottom: 6px;
    cursor: pointer;

    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  })
}

export default class Bookmarks extends React.Component {
  
  constructor(props) {
    super(props)

    if (this.props.location.state.newBookmark) {
      this.state = {
        bookmarks: [],
        filterDropdown: 'closed',
        filter: {},
        pendingBookmark: true,
        newBookmark: this.props.location.state.newBookmark,
        bookmarksInit: false
      }
    }
    else {
      this.state = {
        bookmarks: [],
        filterDropdown: 'closed',
        filter: {},
        pendingBookmark: false,
        bookmarksInit: false
      }
    }
  }

  componentDidMount() {
    this.refreshBookmarks()
  }

  componentWillUpdate = () => {
    if (this.state.pendingBookmark && this.state.newBookmark && isLoggedIn()) {
      if (addBookmark(this.state.newBookmark)) {
        this.setState({
          pendingBookmark: false
        })
        ReactGA.event({
          category: 'Bookmarks',
          action: 'Add bookmark success (after authentication)',
          label: newBookmark.slug
        })
        this.refreshBookmarks()
      }
    }
    if (!this.state.bookmarksInit) this.refreshBookmarks()
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  refreshBookmarks = () => {
    if (isLoggedIn()) this.setState({ bookmarks: getBookmarks() })
    if (isLoggedIn() && !this.state.bookmarksInit) this.setState({ bookmarksInit: true })
  }

  update = () => {
    this.refreshBookmarks()
    this.forceUpdate()
  }

  getTagsFromBookmarks = () => {
    let tags = [];
    this.state.bookmarks.forEach(bookmark => {
      bookmark.tags.forEach(tag => tags.push(tag))
    })

    return removeDuplicates(tags, 'id')
  }

  filter = (tag) => {
    this.setState({filter: tag})
    this.toggleFilterDropdown()
    ReactGA.event({
      category: 'Filter',
      action: 'Filter bookmarks',
      label: tag.name
    })
  }

  clearFilter = () => {
    this.setState({filter: {}})
    this.closeFilterDropdown()
    ReactGA.event({
      category: 'Filter',
      action: 'Filter bookmarks',
      label: 'All'
    })
  }

  hasActiveFilter = () => {
    return this.state.filter.hasOwnProperty('id')
  }

  toggleFilterDropdown = () => {
    const newState = this.state.filterDropdown === 'closed' ? 'open' : 'closed'
    this.setState({filterDropdown: newState})

    if (this.state.filterDropdown === 'open') {
      document.addEventListener('mousedown', this.handleClick, false)
    }
  }

  closeFilterDropdown = () => {
    this.setState({ filterDropdown: 'closed' })
  }

  handleClick = (e) => {
    if ((this.dropdownRef && this.dropdownRef.contains(e.target)) || 
      (this.toggleRef && this.toggleRef.contains(e.target))) {
      return
    }

    this.closeFilterDropdown()
  } 

  getFilteredBookmarks = () => {
    if (!this.state.filter.hasOwnProperty('id')) return this.state.bookmarks

    return this.state.bookmarks.filter(bookmark => {
      let match = false
      bookmark.tags.forEach(tag => {
        if (tag.id === this.state.filter.id) match = true
      })
      return match
    })
  }

  startBookmarking = () => {
    ReactGA.event({
      category: 'Bookmarks',
      action: 'Click start bookmarking button',
      label: 'Start Bookmarking'
    })
    navigate('/')
  }

  render() {
    return (
      <BookmarksContainer>
        <Helmet title={`Bookmarks | P+N Showcase`}>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        { isLoggedIn() ? 
          <div>
            {this.state.bookmarks.length > 0 ? 
              <Wrapper containerPadding>
                <BookmarksHeader>
                  <h1>Bookmarks</h1>
                  <CategoryFilter>
                    <div className={`filter-toggle ${this.state.filterDropdown}`} onClick={this.toggleFilterDropdown} ref={node => this.toggleRef = node}>{this.hasActiveFilter() ? this.state.filter.name : 'All'}</div>
                    <div className={`filter-dropdown ${this.state.filterDropdown}`} ref={node => this.dropdownRef = node}>
                      {this.hasActiveFilter() && <div className="filter-item" onClick={this.clearFilter}>All</div>}
                      {this.getTagsFromBookmarks().map(tag => {
                        if (!this.hasActiveFilter() || tag.id !== this.state.filter.id) return <div className="filter-item" key={tag.id} onClick={() => this.filter(tag)}>{tag.name}</div>
                      })}
                    </div>
                  </CategoryFilter>
                </BookmarksHeader>
                <TileGrid>
                {this.getFilteredBookmarks().map(update => (
                  <UpdateTile update={update} key={update.slug} showBookmark page="bookmarks" callback={this.update} />
                ))}
                {this.getFilteredBookmarks().length < 1 && 
                  <p>Sorry, there are no bookmarks in this category. <a onClick={this.clearFilter} style={{cursor: 'pointer'}}>View all bookmarks</a>.</p>
                }
                </TileGrid>
              </Wrapper>
              :
              <AccountContainer>
                <img src={bookmarksIcon} alt="" />
                <h1 className="section-headline">No Bookmarks</h1>
                <p>With bookmarks, you can save individual updates and navigate back to them whenever you want.</p>
                <Button theme={buttonThemes.primary} onClick={this.startBookmarking}>Start Bookmarking</Button>
              </AccountContainer>
            }
          </div>
          :
          <div>
            <Login linkedInCallback={responseLinkedin} page="Bookmarks" pendingBookmark={this.state.pendingBookmark} />
          </div>
        }
      </BookmarksContainer>
    )
  }
}